<template>
  <div>
    <a-form-model spellcheck="false" ref="form" :model="form" :rules="rules">
      <b-row class="main-container">
        <b-col>
          <a-form-model-item
          name="title"
          prop="title">
            <a-input size="large" required placeholder="Insert title here" v-model="form.title" />
          </a-form-model-item>
          <div class="mb-3">
            <a-form-model-item
              class="editor"
              name="body"
              prop="body">
              <ckeditor
                required
                class="ckeditor"
                :editor="editor"
                v-model="form.body"
                @ready="onEditorReady"
                :config="editorConfig" />
              <div class="editor__word-count text-muted">
                <div>Words: <span>{{ words }}</span></div>
                <div>Characters: <span>{{ characters }}</span></div>
              </div>
            </a-form-model-item>
          </div>
          <div class="card mb-3">
            <div class="card-header py-2 px-3 font-weight-bold">
              Excerpt
            </div>
            <div class="card-body p-3">
              <a-form-model-item class="mb-0" name="excerpt" prop="excerpt">
                <a-textarea required v-model="form.excerpt"></a-textarea>
              </a-form-model-item>
            </div>
          </div>
        </b-col>
        <b-col class="form-tools">
          <vue-sticky-sidebar :topSpacing="12" :bottomSpacing="12">
            <div class="card mb-3">
              <div class="card-header py-2 px-3 font-weight-bold">
                Publishing
              </div>
              <div class="card-body p-3">
                <a-form-model-item class="mb-0" label="Status" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
                  <a-select :default-value="1" v-model="form.is_published" style="width: 190px">
                    <a-select-option :value="1">
                      Publish
                    </a-select-option>
                    <a-select-option :value="0">
                      Draft
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>
              <div class="card-footer bg-gray-1 p-3">
                <b-row class="no-gutters">
                  <b-col cols="5" class="pr-1">
                    <b-button variant="secondary" block>Preview</b-button>
                  </b-col>
                  <b-col cols="7" class="pl-1">
                    <b-button variant="primary" @click="submit()" block>
                      {{ (type === 'create') ? 'Publish' : 'Save' }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="card mb-0">
              <div class="card-header py-2 px-3 font-weight-bold">
                Image
              </div>
              <div class="card-body p-3">
                <div class="media__preview" v-if="Object.keys(form.image_media).length">
                  <button @click.prevent="removeMedia" class="media__remove"><i class="fe fe-x"></i></button>
                  <img :src="`${form.image_media.url}?tr=w-322`"
                    :srcset="`${form.image_media.url}?tr=w-322 1x, ${form.image_media.url}?tr=w-644 2x`"
                    :alt="form.image_media.alt" />
                </div>
                <b-button variant="info" @click.prevent="mediaFinderOpen" block>{{ Object.keys(form.image_media).length ? 'Change Media' : 'Add Media' }}</b-button>
              </div>
            </div>
          </vue-sticky-sidebar>
        </b-col>
      </b-row>
    </a-form-model>

    <!-- Media Finder for Page Content -->
    <b-modal hide-footer id="page-media-finder" size="xl" body-class="p-0 bg-light" centered title="Add Media">
      <MediaFinder multiple v-on:insert="insertMediaToPage" />
    </b-modal>

    <!-- Media Finder for Featured/Hero Image -->
    <b-modal hide-footer id="media-finder" size="xl" body-class="p-0 bg-light" centered title="Add Media">
      <MediaFinder :current="[form.image_media]" v-on:insert="applyMedia" />
    </b-modal>
  </div>
</template>
<script>
import app from '@/main'
import { mapState } from 'vuex'

import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'

import MediaFinder from '../media/finder'

import apiClient from '@/services/axios'

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import List from '@ckeditor/ckeditor5-list/src/list'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount'

import Image from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'

import vueStickySidebar from 'vue-sticky-sidebar'

class InsertImage extends Plugin {
  init() {
    const editor = this.editor
    editor.ui.componentFactory.add('insertMediaToPage', locale => {
      const view = new ButtonView(locale)
      view.set({
        label: 'Insert image',
        icon: imageIcon,
        tooltip: true,
      })
      view.on('execute', () => {
        app.$bvModal.show('page-media-finder')
        app.$store.dispatch('media/FETCH')
      })
      return view
    })
  }
}

function escapeHtml(unsafe) {
  const output = unsafe.replace(/<[^>]+>/g, '')
  return output.slice(0, 150)
}
export default {
  name: 'mainForm',
  props: {
    type: {
      type: String,
      default: 'create',
    },
    // tagsids: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: 'Please insert a title' }],
        body: [{ required: true, message: 'Please write the body content' }],
        excerpt: [{ required: true, message: 'Please write the excerpt' }],
      },
      mediaFile: [],
      checkedKeys: [],
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Heading,
          List,
          Indent,
          Image,
          ImageToolbar,
          ImageStyle,
          ImageCaption,
          InsertImage,
          Table,
          TableToolbar,
          BlockQuote,
          PasteFromOffice,
          TextTransformation,
          WordCount,
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'indent',
            'outdent',
            '|',
            'insertMediaToPage',
            'blockQuote',
            'insertTable',
            '|',
            'undo',
            'redo',
          ],
        },
        image: {
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative',
          ],
          styles: ['full', 'side', 'alignLeft', 'alignCenter', 'alignRight'],
        },
        wordCount: {
          onUpdate: stats => {
            this.words = stats.words
            this.characters = stats.characters
          },
        },
      },
      words: 0,
      characters: 0,
      // tag_ids: this.tagsids,
      editorReady: false,
      ckEditor: {},
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
    ...mapState('page', {
      form: state => state.form,
    }),
    // ...mapState('tag', {
    //   tags: state => state.data,
    //   tagLoading: state => state.loading,
    //   tagsForm: state => state.form,
    // }),
    loading() {
      return this.$store.state.page.loading
    },
  },
  methods: {
    submit() {
      const vm = this

      // vm.$set(vm.form, 'tags', vm.tag_ids)
      vm.$refs.form.validate(valid => {
        if (valid) {
          vm.$emit('submit')
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    // Media
    mediaFinderOpen() {
      const vm = this

      vm.$bvModal.show('media-finder')
    },
    removeMedia() {
      const vm = this
      vm.$set(vm.form, 'image_media_id', null)
      vm.$set(vm.form, 'image_media', {})
    },
    uploadMedia(data) {
      this.$store.commit('media/SET_STATE', {
        loading: true,
      })
      const formData = new FormData()
      formData.append('image', data.file)
      formData.append('name', data.file.name)
      formData.append('alt', data.file.name)
      formData.append('portals[0]', this.settings.activePortal)
      return apiClient({
        method: 'post',
        url: 'v1/media',
        data: formData,
        onUploadProgress: ({ total, loaded }) => {
          data.onProgress({ percent: Number(Math.round((loaded / total) * 100).toFixed(2)) }, data.file)
        },
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((res) => {
          this.$set(this.form, 'image_id', res.data.data.id)
          data.onSuccess(res, data.file)
          this.mediaFile = {
            uid: data.file.uid, // The unique identifier of the file, it is recommended to set a negative number to prevent conflicts with internally generated id
            name: data.file.name, // file name
            status: 'done', // Status: uploading done error removed
            response: res, // server response content
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    insertMediaToPage(media, medias) {
      const vm = this
      const editor = vm.ckEditor

      const content = editor.model.change(writer => {
        const docFrag = writer.createDocumentFragment()

        medias.forEach(item => {
          const imageElement = writer.createElement('image', {
            src: item.url,
          })
          writer.append(imageElement, docFrag)
        })
        return docFrag
      })
      editor.model.insertContent(content)
      vm.$bvModal.hide('page-media-finder')
    },
    applyMedia(media, medias) {
      // console.log(media, medias)
      const vm = this
      vm.$set(vm.form, 'image_media', media)
      vm.$set(vm.form, 'image_media_id', media.id)
      vm.$bvModal.hide('media-finder')
    },
    // Editor
    onEditorReady(editor) {
      this.ckEditor = editor
      this.editorReady = true
    },
    // Tags
    // tagSearch(search) {
    //   this.$store.dispatch('tag/FETCH', { q: search })
    // },
    // tagChange(value, option) {
    //   const vm = this
    //   const ids = []
    //   value.forEach((tag) => {
    //     const slug = tag.toLowerCase()
    //       .replace(/ /g, '-')
    //       .replace(/[^\w-]+/g, '')
    //     vm.$store.dispatch('tag/GET_BY_SLUG', slug).then((res) => {
    //       if (!res.data) {
    //         vm.$set(vm.tagsForm, 'name', tag)
    //         vm.$set(vm.tagsForm, 'portal', [vm.settings.activePortal])
    //         vm.$set(vm.tagsForm, 'created_by', vm.user.id)
    //         vm.$store.dispatch('tag/STORE').then((result) => {
    //           ids.push(result.data.data.id)
    //         })
    //       } else {
    //         ids.push(res.data.id)
    //       }
    //     })
    //   })
    //   vm.$set(vm, 'tag_ids', ids)
    // },
    // tagSelect(value, option) {},
  },
  watch: {
    'form.body': function(body) {
      this.$set(this.form, 'excerpt', escapeHtml(body))
    },
  },
  components: {
    MediaFinder,
    'vue-sticky-sidebar': vueStickySidebar,
  },
  destroyed() {
    this.$store.commit('page/CLEAR_FORM')
    this.$store.commit('tag/CLEAR_DATA')
    this.$store.commit('tag/CLEAR_FORM')
  },
}
</script>

<style lang="scss">
@import "./style.module.scss";
.modal {
  // z-index: 998;
}
.editor {
  line-height: normal !important;
  .ant-form-item-control {
    line-height: normal !important;
  }
}
</style>
